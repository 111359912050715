import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FaShareAlt } from "react-icons/fa";
// import ShareButton from "../../components/ShareButton";
import Header from "../../components/NavBar";
import Footer from "../../components/Footer";
import "./style.css";

const ArticlePage = () => {
  const { slug } = useParams(); // Get slug from URL parameters
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await fetch(
          `https://homchang.in//get_article.php?slug=${slug}`
        );
        const data = await response.json();

        if (data.success) {
          setArticle(data.article);
        } else {
          setError(data.message);
        }
      } catch (error) {
        console.error("Error fetching article:", error);
        setError("An error occurred while fetching the article.");
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [slug]);



  if (loading)
    return (
      <div className="flex justify-center items-center h-screen">
        <p>Loading...</p>
      </div>
    );

  if (error)
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-red-500">{error}</p>
      </div>
    );

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: article.Title,
          text: `Check out this article: ${article.Title}`,
          url: window.location.href,
        })
        .then(() => console.log("Article shared successfully"))
        .catch((err) => console.error("Error sharing article:", err));
    } else {
      alert("Sharing is not supported in this browser.");
    }
  };

  return (
    <div className="bg-gray-200">
      <div className="bg-black">
        <Header />
      </div>
      <div className="main-body justify-center">
        <div className="larger-section">
          <div className=" mx-auto p-6">
            {/* Article Title */}
            <p className="text-sm text-gray-600 mb-4 flex border-b border-black pb-2">
              <span className="font-semibold"></span>{" "}
              {article.CategoryName || "Uncategorized"}
              {/* <ShareButton url={articleUrl} title={articleTitle} text={articleDescription} /> */}
            </p>
            <h1 className="text-4xl font-bold text-start text-gray-800">
              {article.Title}
            </h1>
            {/* Article Metadata */}
            <div className="text-sm text-gray-600 mb-4 flex justify-between items-center border-b border-black pb-2">
              <p>
                <span className="font-semibold"> By:</span>{" "}
                {article.AuthorName || "Unknown"}
              </p>
              <p>
                <span className="font-semibold"> Updated On : </span>{" "}
                {new Date(article.PublishedDate).toLocaleDateString()}
              </p>
            </div>
            {/* Share Button */}
            <div className="mb-2 flex items-center">
              <button
                onClick={handleShare}
                className="flex items-center text-black outline outline-offset-2 outline-solid outline-offset-2 py-2 px-2 hover:underline"
              >
                <FaShareAlt className="w-3 h-3" />
              </button>
            </div>

            {/* Article Content */}
            <div
              className="prose-body"
              dangerouslySetInnerHTML={{ __html: article.Content }}
            />
          </div>
        </div>
        <div className="smaller-section">{/* Empty section */}</div>
      </div>
      <Footer />
    </div>
  );
};

export default ArticlePage;
