import React, { useState, useEffect } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import AdminSidebar from "./SideBar";
import AdminHeader from "./Header";

const ArticleHistory = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [articles, setArticles] = useState([]);
  const navigate = useNavigate(); // Initialize navigate function

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(true);
  };

  const fetchData = async (type, setData) => {
    try {
      const response = await fetch(`https://homchang.in//get_data.php?type=${type}`);
      const result = await response.json();
      if (result.success) {
        setData(result.data);
      } else {
        console.error(`Error fetching ${type}:`, result.message);
      }
    } catch (error) {
      console.error(`Error fetching ${type}:`, error);
    }
  };

  useEffect(() => {
    fetchData("articles", setArticles);
  }, []);

  const handleDeleteArticle = async (articleID) => {
    if (window.confirm("Are you sure you want to delete this article?")) {
      try {
        const response = await fetch(`https://homchang.in//delete_article.php`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({ id: articleID }), // Send ID in body
        });

        const result = await response.json();
        if (result.success) {
          alert("Article deleted successfully!");
          fetchData("articles", setArticles); // Refresh articles list after deletion
        } else {
          alert("Failed to delete article: " + result.message);
        }
      } catch (error) {
        console.error("Error deleting article:", error);
      }
    }
  };

  return (
    <div className="flex h-full bg-gray-100">
      <AdminSidebar isOpen={isSidebarOpen} closeSidebar={closeSidebar} />
      <div className={`flex-1 transition-all duration-300 ${isSidebarOpen ? "ml-64" : "ml-0"}`}>
        <AdminHeader toggleSidebar={toggleSidebar} title="Article History" />
        <div className="pt-20 px-6">
          <table className="w-full bg-white shadow-md rounded-lg overflow-hidden">
            <thead className="bg-gray-200 text-gray-600 text-sm uppercase">
              <tr>
                <th className="p-4 text-left">ID</th>
                <th className="p-4 text-left">Title</th>
                <th className="p-4 text-left">Page URL</th>
                <th className="p-4 text-left">Date</th>
                <th className="p-4 text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              {articles.map((article) => (
                <tr key={article.ArticleID} className="border-t">
                  <td className="px-4 py-2 truncate max-w-xs">{article.ArticleID}</td>
                  <td className="px-4 py-2 truncate max-w-xs">{article.Title}</td>
                  <td className="px-4 py-2 truncate max-w-xs">
                    <a
                      href={`/articles/${article.Slug}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:underline"
                    >
                      /articles/{article.Slug}
                    </a>
                  </td>
                  <td className="px-4 py-2 truncate max-w-xs">{article.PublishedDate}</td>
                  <td className="px-4 py-2 flex space-x-2 text-center truncate max-w-xs">
                    {/* Edit Button with Navigation */}
                    <button
                      onClick={() => navigate(`/edit-article/${article.Slug}`)} // Redirect to update page
                      className="text-blue-500 hover:underline"
                    >
                      <FaEdit />
                    </button>

                    {/* Delete Button */}
                    <button
                      onClick={() => handleDeleteArticle(article.ArticleID)}
                      className="text-red-500 hover:underline"
                    >
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ArticleHistory;