import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom"; // Import useParams
import AdminSidebar from "./SideBar";
import AdminHeader from "./Header";
import './style.css'; // Ensure you have this CSS file for custom styles

const UpdateArticle = () => {
  const { slug } = useParams(); // Get slug from URL
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [authors, setAuthors] = useState([]); // Initialize as empty array
  const [categories, setCategories] = useState([]); // Initialize as empty array
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({ text: "", type: "" });
  const [articleData, setArticleData] = useState({
    title: "",
    content: "",
    publishedDate: "",
    authorID: "",
    categoryID: "",
    slug: "", // Include slug in state
  });

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  const closeSidebar = () => setIsSidebarOpen(true);

  const fetchData = async (type, setData) => {
    try {
      const response = await fetch(`https://homchang.in//get_data.php?type=${type}`);
      const result = await response.json();
      if (result.success) {
        setData(result.data);
      } else {
        console.error(`Error fetching ${type}:`, result.message);
      }
    } catch (error) {
      console.error(`Error fetching ${type}:`, error);
    }
  };

  useEffect(() => {
    fetchData("authors", setAuthors);
    fetchData("categories", setCategories);
    fetchArticle(slug); // Fetch article data based on slug
  }, [slug]);

  const fetchArticle = async (slug) => {
    try {
      const response = await fetch(`https://homchang.in//get_article_by_slug.php?slug=${slug}`);
      const result = await response.json();
      if (result.success) {
        setArticleData(result.data); // Populate form fields with existing article data
      } else {
        console.error("Error fetching article:", result.message);
      }
    } catch (error) {
      console.error("Fetch Error:", error);
    }
  };

  const handleArticleUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage({ text: "", type: "" });

    const formData = new FormData();
    formData.append("title", articleData.title);
    formData.append("content", articleData.content);
    formData.append("publishedDate", articleData.publishedDate);
    formData.append("authorID", articleData.authorID);
    formData.append("categoryID", articleData.categoryID);
    formData.append("slug", articleData.slug); // Use existing slug

    try {
      const response = await fetch("https://homchang.in//update_articles.php", {
        method: "POST",
        body: formData,
      });

      const result = await response.json();
      setIsLoading(false);

      if (result.success) {
        setMessage({
          text: "Article updated successfully!",
          type: "success",
        });
        // Optionally redirect or reset the form
      } else {
        console.error("Server Error:", result.message);
        setMessage({ text: result.message, type: "error" });
      }
    } catch (error) {
      console.error("Fetch Error:", error);
      setIsLoading(false);
      setMessage({
        text: "An error occurred while updating the article.",
        type: "error",
      });
    }
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <AdminSidebar isOpen={isSidebarOpen} closeSidebar={closeSidebar} />
      <div className={`flex-1 transition-all duration-300 ${isSidebarOpen ? "ml-64" : "ml-0"}`}>
        <AdminHeader toggleSidebar={toggleSidebar} title="Update Article" />
        <div className="pt-20 px-8">
          <h2 className="text-4xl font-bold mb-6 text-gray-800">Update Article</h2>

          {message.text && (
            <div className={`p-4 rounded-md mb-6 ${message.type === "success" ? "bg-green-100 text-green-700" : message.type === "info" ? "bg-blue-100 text-blue-700" : "bg-red-100 text-red-700"}`}>
              {message.text}
            </div>
          )}
          
          <form onSubmit={handleArticleUpdate} className="space-y-6">
            <div>
              <label className="block text-gray-700 font-medium mb-2">Title</label>
              <input
                type="text"
                placeholder="Enter article title"
                value={articleData.title}
                onChange={(e) =>
                  setArticleData({ ...articleData, title: e.target.value })
                }
                required
                className="w-full border-gray-300 rounded-lg p-3 focus:ring focus:ring-blue-200 focus:outline-none"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">Content</label>
              <ReactQuill
                value={articleData.content}
                onChange={(content) =>
                  setArticleData({ ...articleData, content })
                }
                modules={{
                  toolbar: [
                    [{ header: "1" }, { header: "2" }, { font: [] }],
                    [{ size: [] }],
                    ["bold", "italic", "underline", "strike"],
                    [{ color: [] }, { background: [] }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ align: [] }],
                    ["link"],
                  ],
                }}
                placeholder="Write your article content here..."
                className="custom-quill"
              />
            </div>
            <div className="flex flex-wrap gap-6">
              <div className="flex-1">
                <label className="block text-gray-700 font-medium mb-2">Published Date</label>
                <input
                  type="date"
                  value={articleData.publishedDate}
                  onChange={(e) =>
                    setArticleData({
                      ...articleData,
                      publishedDate: e.target.value,
                    })
                  }
                  required
                  className="w-full border-gray-300 rounded-lg p-3 focus:ring focus:ring-blue-200 focus:outline-none"
                />
              </div>
              <div className="flex-1">
                <label className="block text-gray-700 font-medium mb-2">Author</label>
                <select
                  value={articleData.authorID}
                  onChange={(e) =>
                    setArticleData({ ...articleData, authorID: e.target.value })
                  }
                  required
                  className="w-full border-gray-300 rounded-lg p-3 focus:ring focus:ring-blue-200 focus:outline-none"
                >
                  <option value="">Select an author</option>
                  {authors.map((author) => (
                    <option key={author.AuthorID} value={author.AuthorID}>
                      {author.Name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex-1">
                <label className="block text-gray-700 font-medium mb-2">Category</label>
                <select
                  value={articleData.categoryID}
                  onChange={(e) =>
                    setArticleData({
                      ...articleData,
                      categoryID: e.target.value,
                    })
                  }
                  required
                  className="w-full border-gray-300 rounded-lg p-3 focus:ring focus:ring-blue200 focus:outline-none"
                >
                  <option value="">Select a category</option>
                  {categories.map((category) => (
                    <option key={category.CategoryID} value={category.CategoryID}>
                      {category.CategoryName}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              disabled={isLoading}
              className={`w-full py-3 rounded-lg text-white ${isLoading ? "bg-blue-400 cursor-notallowed" : "bg-blue-600 hover:bg-blue-700"}`}
            >
              {isLoading ? "Updating..." : "Update Article"}
            </button>

          </form>

        </div>

      </div>

    </div>

  );
};

export default UpdateArticle;
