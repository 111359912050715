// import React, { useEffect, useState } from "react";
import React from "react";
import Header from "../../components/Header";
import ArticleCard from "../../components/ArticleCard";
import Footer from "../../components/Footer";
import './style.css';

const Home = () => {

  // const [headline, setHeadline] = useState('');
  // const [currentDate, setCurrentDate] = useState('');
  // const [headlineUrl, setHeadlineUrl] = useState('');

  // useEffect(() => {
  //   // Fetch the latest headline from the backend
  //   fetch('https://homchang.in//headlines.php')
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.length > 0) {
  //         const latestHeadline = data[0]; // Assuming the data is sorted by date (latest first)
  //         setHeadline(latestHeadline.title);

  //         // Format the date as "D Month, Yr" (e.g., "17 January, 2025")
  //         const formattedDate = new Date(latestHeadline.timestamp).toLocaleDateString('en-US', {
  //           day: '2-digit',
  //           month: 'long',
  //           year: 'numeric',
  //         });
  //         setCurrentDate(formattedDate);

  //         setHeadlineUrl(latestHeadline.url);
  //       }
  //     })
  //     .catch((error) => console.error('Error fetching headline:', error));
  // }, []);

  const headline = "Kokborok educhart available, get yours by registering";
  const headlineUrl = "/Kokborok-educhart-registration";

  return (
    <>
      {/* <Header headline={headline} date={`Published on ${currentDate}`} navigateTo={headlineUrl} /> */}
      <Header headline={headline} navigateTo={headlineUrl} />
      <div className="main-body">
        <div className="larger-section">
          <ArticleCard />
        </div>

        <div className="smaller-section">
          {/* Empty section */}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Home;
